// const { data } = require("jquery");

function jsonToFormData(json) {
  const formData = new FormData();
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      formData.append(key, json[key]);
    }
  }
  return formData;
}

// formData = {
//   name: "test",
//   name: "test",
// }

// let url         = '';
// let redirect    = '';
// let location    = '';
// Ajax(formData, url, redirect, location);
function Ajax(formData, url, redirect, location) {
  return $.ajax({
    type: "POST",
    url: url,
    data: formData,
    dataType: "json",
    encode: true,
  }).done(function (data) {

    console.log(data);

    if (!data.success) {
      
      if(data.errormessage == 'Please login first!')
      {
        new swal({
          timer: 2000,
          icon: "warning",
          text: data.errormessage,
          buttons: {
            cancel: false,
            confirm: false,
          },
        }).then(function () {
          window.location.href = "./admin_login.html";
        });
      }

      if (data.error && data.error.errormessage) {
        new swal({
          timer: 2000,
          icon: "warning",
          text: data.error.errormessage,
          buttons: {
            cancel: false,
            confirm: false,
          },
        });
      }

      if (data.error && data.error.message)
      {
        new swal({
          timer: 2000,
          icon: "warning",
          text: data.error.message,
          buttons: {
            cancel: false,
            confirm: false,
          },
        });
      }
    } else {
      if (redirect == "continue") {
        // stop here
      } else {
        new swal({
          timer: 1500,
          text: data.successmessage,
          buttons: {
            cancel: false,
            confirm: false,
          },
          title: "",
          icon: "success",
        }).then(function () {
          if (redirect == "reload") {
            window.location.reload();
          } else if (redirect == "redirect") {
            window.location.href = location;
          } else {
            console.log("redirect action fail");
          }
        });
      }
    }
  });
}


window.Ajax = Ajax;

/**
 * 以下是pass 照片的AJAX
 * <form  id="formdata" action="include/db_ad-promotionadd.php" method="post" enctype="multipart/form-data">
 */

function AjaxImage(formData, url, redirect, location) {
  return $.ajax({
    type: "POST",
    url: url,
    data: formData,
    contentType: false,
    cache: false,
    processData: false,
  }).done(function (data) {

    // console.log(data);

    if (!data.success) {
      if (data.error && redirect !== "continue") {
        new swal({
          title: "",
          text: data.error.errormessage,
          icon: "warning",
          buttons: {
            cancel: false,
            confirm: false,
          },
        });
      }
    } else {
      new swal({
        text: data.successmessage,
        icon: "success",
        tittle: "",
        buttons: {
          confirm: false,
          cancel: false,
        },
      }).then(function () {
        if (redirect == "reload") {
          window.location.reload();
        } else if (redirect == "redirect") {
          window.location.href = location;
        } else if (redirect == "continue") {
          // stop here
        } else {
          console.log("redirect action fail");
        }
      });
    }
  });
}


window.AjaxImage = AjaxImage;

// $.ajax({
//     type: 'POST',
//     url: url,
//     data: formData,
//     contentType: false,
//     cache: false,
//     processData: false,
//     success: function(data){
//         console.log(data);
//     }
// })

// getData(url)
function getData(url) {
  return $.ajax({
    type: "GET",
    url: url,
    dataType: "json",
  });
}

window.getData = getData;
